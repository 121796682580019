import React from "react"

const ContentBodyImage = ({ image }) => (
  <div className="content-block--images">
    {image.length > 0 &&
      image.map(figure => (
        <figure key={`figure-${figure.id}`}>
          <>
            <img
              src={`https://res.cloudinary.com/amplifiedbydesign/image/upload/c_scale,w_800/${
                figure.filename
              }`}
              alt={
                figure.imageCaption ? figure.imageCaption.content : figure.title
              }
            />
            {figure.imageCaption && figure.imageCaption.content && (
              <figcaption>{figure.imageCaption.content}</figcaption>
            )}
          </>
        </figure>
      ))}
  </div>
)

export default ContentBodyImage
