import React from "react"
import { CLOUDINARY_URL } from "../../constants"

import "./imageWithCaption.scss"

const ContentBodyImageWithCaption = ({ image, richText }) => {
  const imageUrl = `${CLOUDINARY_URL}/fl_sanitize/${image[0].filename}`

  return (
    <div
      className={`content-block content-block--image ${richText.content.includes(
        "font--right"
      ) && "align-right"}`}
    >
      {image.length > 0 && (
        <img
          src={imageUrl}
          className={`${richText.content.includes("pbl") && "position--top"}`}
          alt={
            image[0].imageCaption
              ? image[0].imageCaption.content
              : image[0].title
          }
        />
      )}
      <div
        className={`content-block--image-caption ${
          richText.content.includes("pbl")
            ? "bottom-left--quote"
            : "top-right--quote"
        }`}
        dangerouslySetInnerHTML={{ __html: richText.content }}
      />
    </div>
  )
}

export default ContentBodyImageWithCaption
