import React from "react"

const ContentBodyText = ({ richText }) => (
  <div
    className={`content-body--text`}
    dangerouslySetInnerHTML={{ __html: richText.content }}
  />
)

export default ContentBodyText
