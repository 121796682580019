import React from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout/siteLayout"
import SEO from "../components/layout/seo"
import PageHeader from "../components/pageHeader"
import ContentBodyText from "../components/contentBody/text"
import ContentBodyImageWithCaption from "../components/contentBody/imageWithCaption"
import ContentBodyImage from "../components/contentBody/image"

import "./caseStudyPost.scss"
import NavigationArrowWhiteIcon from "../images/navigation_arrow--white.svg"

const ContentBlocks = {
  Craft_ContentBodyText: ContentBodyText,
  Craft_ContentBodyImageWithCaption: ContentBodyImageWithCaption,
  Craft_ContentBodyImage: ContentBodyImage,
}

const CaseStudyPost = ({ data, location }) => {
  const {
    title,
    subtitle,
    caseStudyType,
    heroImage,
    color,
    contentBody,
  } = data.craft.entry

  return (
    <Layout location={location}>
      <Helmet bodyAttributes={{ class: "case-study-details" }} />
      <SEO title={`${title} | Work`} location={location} />
      <PageHeader
        heroImage={heroImage}
        color={color}
        className="case-study__heading"
        postType={<div className="layout__header-type">{caseStudyType}</div>}
      >
        <>
          <h1>{title}</h1>
        </>
      </PageHeader>

      <section className="layout__section layout__article">
        <div className="layout__main">
          <div className="layout__inner layout__inner--full-width">
            <div className="content-body--text">
              <h2>{subtitle}</h2>
            </div>
            {contentBody.length > 0 &&
              contentBody.map(block => {
                const BlockComponent = ContentBlocks[block.__typename]
                return (
                  <React.Fragment key={block.id}>
                    <BlockComponent
                      key={`contentBlock-${block.__typename.toLowerCase()}-${
                        block.id
                      }`}
                      {...block}
                    />
                  </React.Fragment>
                )
              })}
          </div>

          <div className="btns-group">
            <div className="btns-group__left-btns">
              <Link to="/work" className="btn btn--back btn--solid btn--accent">
                <NavigationArrowWhiteIcon alt="Arrow" /> More Case Studies
              </Link>
            </div>
            <div className="btns-group__right-btns">
              <button type="button" className="btn btn--gray-medium">
                Share
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const getCaseStudyPostQuery = graphql`
  query($slug: String!) {
    craft {
      entry(section: [caseStudy], slug: $slug) {
        ... on Craft_CaseStudy {
          id
          title
          subtitle
          postDate
          caseStudyType
          heroImage {
            ... on Craft_HeroVolume {
              filename
              focalPoint {
                x
                y
              }
              heroImageSize
              heroImageSizeModifier
            }
          }
          color
          contentBody {
            ... on Craft_ContentBodyText {
              __typename
              id
              richText {
                content
              }
            }
            ... on Craft_ContentBodyImageWithCaption {
              __typename
              id
              image {
                ... on Craft_CloudImagesVolume {
                  id
                  imageCaption {
                    content
                  }
                  filename
                  focalPoint {
                    x
                    y
                  }
                  title
                }
              }
              richText {
                content
              }
            }
            ... on Craft_ContentBodyImage {
              __typename
              id
              image {
                ... on Craft_CloudImagesVolume {
                  id
                  imageCaption {
                    content
                  }
                  filename
                  focalPoint {
                    x
                    y
                  }
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`

export default CaseStudyPost
